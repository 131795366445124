import $ from 'jquery'
import * as project from './functions'
import * as anim from './animation'
import './polyfill'

window.onload = () => {
  project.lazyLoad()
  project.smoothScroll()
  project.menuOverlay()
  project.setupModalVideo()
  project.setupAccordion()
  project.setupMenu()
  anim.setupAnimation()

  if ($('.slider-init').length > 0) {
    $('.slider-init').each(function() {
      project.setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
    })
  }



  const bodyClasses = $('body').attr("class").split(/\s+/)  
  $.each(bodyClasses, function(key, value) {
    switch(value) {
      case "home":
        anim.homepagePageAnimation()
        anim.pageDefaultAnimation()
        // anim.setupAnimation()
        anim.animGallery()
        $('#loader').delay(2000).fadeOut(400)
        break
      case "single-product":
        project.productTicker()
        anim.productPageAnimation()
        // anim.setupAnimation()
        break
      case "single-accessory":
        //project.productTicker()
        anim.accessoryPageAnimation()
        // anim.setupAnimation()
        break
      case "tax-product_category":
        anim.categoryPageAnimation()
        // anim.setupAnimation()
        break
      case "page-template-default":
        anim.animGallery()
        // anim.setupAnimation()
        anim.pageDefaultAnimation()
        project.pageSliderCounter()
        anim.footerAnimation()
        break
      case "single-post":
        anim.postPageAnimation()
        break
      default:
        $('#loader').fadeOut(400)
        anim.footerAnimation()
    }
  })
}

$(document).on('facetwp-refresh', function () {
  FWP.enable_scroll = FWP.soft_refresh == true
})

$(document).on('facetwp-loaded', function () {
  if (FWP.enable_scroll == true) $('html, body').animate({ scrollTop: 0 }, 500)
  project.lazyLoad()
})