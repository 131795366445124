import $ from 'jquery'
import LazyLoad from "vanilla-lazyload"
import { gsap, Power2, Linear } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CustomEase } from "gsap/CustomEase"
gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollTrigger)

/* FOOTER ANIMATION
	----------------------------- */
export const footerAnimation = () => {
	//if ($(".content-area > .container > section:last-child, .page--flexible-content > section:last-child").hasClass('background--gray-dark')) return
	if($(window).width < 650) {
		gsap.to(".content-area > .container > section:last-child, .page--flexible-content > section:last-child", {
			scrollTrigger: {
				trigger: "footer.footer",
				start: "top 100%",
				scrub: 1,
				invalidateOnRefresh: true
			},
			width: "calc(100% - 10rem)",
			left: '5rem'
		})
	}
	
}

/* STANDARD ANIMATIONS
	----------------------------- */
export const setupAnimation = () => {
	// FadiInUp
	const fadeInUpItems = gsap.utils.toArray('.fadeInUp')
	fadeInUpItems.forEach(function (item, index) {
		gsap.to(item, {
			scrollTrigger: {
				trigger: item,
				start: "top 88%",
				toggleActions: "play none none none",
				invalidateOnRefresh: true
			},
			//scaleY: '1.2',
			autoAlpha: 1,
			y: -80,
			transformOrigin: "top top",
			duration: 0.6,
			ease: Power2.easeOut
		})
	})

	// Values + Contacts + Sentence List 
	const sectionPinScroll = gsap.utils.toArray('.section--home-values .section__image-wrapper, .section--home-contacts .section__image-wrapper')
	sectionPinScroll.forEach(function (item, index) {
		ScrollTrigger.create({
			trigger: item,
			start: "top 0",
			pin: true,
			pinSpacing: true,
			anticipatePin: 0,
			scrub: 2,
			invalidateOnRefresh: true
			// markers: true
		})

		const sectionPinScrollTl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				start: "top top",
				scrub: 2,
				//markers: true,
				toggleActions: "play pause",
				invalidateOnRefresh: true
			}
		})

		sectionPinScrollTl.to(item, { alpha: 0.3, duration: 2 }, '+=0')
	})
}

/* HOMEPAGE ANIMATION
  ----------------------------- */
export const homepagePageAnimation = () => {
	window.scrollTo(0, 0)

	const tlIntro = gsap.timeline({ ease: Power2.easeOut })
	tlIntro
		.from('.section--home-hero .title--line-wrapper .title-text', { autoAlpha: 0, yPercent: 100, stagger: 0.15, duration: 1.2 }, '+=2.8')
		.from('.section--home-hero .section__decorator', { autoAlpha: 0, width: 0, duration: 0.4 }, '+=0')
		.add(function () {
			$('body').addClass('scroll')
		})

	ScrollTrigger.create({
		trigger: '.section--home-hero .section__image-wrapper, .section--home-hero .section__video-wrapper',
		start: "top 80px",
		pin: true,
		pinSpacing: true,
		end: "+=" + ($('.section--home-hero').innerHeight()),
		anticipatePin: 1,
		invalidateOnRefresh: true
		// markers: true
	})

	// Content First
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: '.section--home-hero',
			start: "top 20vh",
			end: "+=" + ($('.section--home-hero').innerHeight()),
			scrub: 1,
			//markers: true,
			toggleActions: "play pause",
			invalidateOnRefresh: true
		}
	})

	tl.to('.section--home-hero .section__image-wrapper, .section--home-hero .section__video-wrapper', { alpha: 0, filter: "blur(20px)", duration: 4 }, '+=0')


	gsap.to(".section--home-alternates .section__image-wrapper figure", {
		ease: 'none',
		scrollTrigger: {
			trigger: ".section--home-alternates",
			scrub: 3,
			start: "top center",
			invalidateOnRefresh: true
		},
		yPercent: -20
	});

	gsap.to(".section--home-masonry .section__image-wrapper:nth-child(2n)", {
		ease: 'none',
		scrollTrigger: {
			trigger: ".section--home-masonry",
			scrub: 3,
			start: "top center",
			invalidateOnRefresh: true
		},
		yPercent: -30
	});


	gsap.to(".page-template-homepage", {
		scrollTrigger: {
			trigger: ".page-background--gray-light",
			start: "top 88%",
			end: "4rem",
			scrub: 2,
			invalidateOnRefresh: true
			//markers: true
		},
		backgroundColor: "#F2F2F2"
	})

	gsap.to(".page-template-homepage", {
		scrollTrigger: {
			trigger: ".page-background--gray-dark",
			start: "top 88%",
			end: "4rem",
			scrub: 2,
			invalidateOnRefresh: true
			//markers: true
		},
		backgroundColor: "#282829"
	})

	

}

/* PRODUCT ANIMATION
  ----------------------------- */
export const productPageAnimation = () => {
	window.scrollTo(0,0)

	const tlIntro = gsap.timeline({ ease: Power2.easeOut })
	tlIntro
		.from('.product__title--line-wrapper .product__title-text', { autoAlpha: 0, yPercent: 100, stagger: 0.2, duration: 0.8 }, '+=0.5')
		.from('.product__title--decorator', { autoAlpha: 0, width: 0,  duration: 0.4 }, '+=0')
		.from('.product__abstract, .product__abstract p', { autoAlpha: 0, y: 45,  duration: 0.8 }, '-=0.4')
		.add(function () {
			$('body').addClass('scroll')
		})
		.from('.product__image-wrapper', { autoAlpha: 0, yPercent: 10, duration: 0.8 }, '-=0.4')
		.from('.product__ticker-wrapper', { autoAlpha: 0,  duration: 2.4 }, '-=0.4')

	ScrollTrigger.create({
		trigger: '.product--hero',
		start: "top 6rem",
		pin: true,
		pinSpacing: true,
		end: "+=" + (window.innerHeight * 3),
		anticipatePin: 1,
		invalidateOnRefresh: true
		// markers: true
	})

	let _end = "+=" + (window.innerHeight * 3)
	if ($(window).width() < 768) {
		_end = "+=" + (window.innerHeight * 1.4)
	}

	// Content First
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: '.product--hero',
			start: "top 6rem",
			end: _end,
			scrub: 1,
			//markers: true,
			toggleActions: "play pause",
			invalidateOnRefresh: true
		}
	})


	if($(window).width() > 768) {
		tl
			.to('.product__title--line-wrapper ', { autoAlpha: 1, duration: 2, stagger: 0.01 }, '+=0')
			.to('.product__abstract p', { autoAlpha: 0, duration: 2 }, '+=0')
			.to('.product__image-wrapper img', { width: '50%', duration: 4 }, '+=0')
			.to('.product__overview-container', { yPercent: -15, duration: 4 }, '-=1')
			.to('.product__ticker', { alpha: 0, duration: 4 }, '-=2')
			.to('.product__traits-wrapper', { autoAlpha: 1, y: 0, duration: 4 }, '+=0')
			.to('.product__traits-wrapper', { autoAlpha: 0, duration: 4 }, '+=1')
			.to('.product__image-wrapper img', { width: '100%', duration: 4 }, '+=0')
			.to('.product__abstract p', { autoAlpha: 1, duration: 4 }, '-=4')
			.to('.product__overview-container', { yPercent: 0, duration: 4 }, '-=4')
	} else {
		tl
			.to('.product__abstract p', { autoAlpha: 0, duration: 2 }, '+=0')
			.to('.product__image-wrapper img', { scale: 1.5, duration: 4 }, '+=0')
			.to('.product__ticker', { alpha: 0, duration: 4 }, '-=2')
			.to('.product__traits-wrapper', { autoAlpha: 1, y: 0, duration: 4 }, '+=0')

	}
		

	ScrollTrigger.config({
		//ignoreMobileResize: true,
	})

	// Page Color Change
	gsap.fromTo(".product-template-default", {
		backgroundColor: gsap.getProperty("html", "--dark")
		}, {
			scrollTrigger: {
			trigger: ".color-light",
			scrub: 1,
			end: "bottom bottom",
			invalidateOnRefresh: true
		},
		backgroundColor: gsap.getProperty("html", "--light")
	})
}

/* ACCESSORY ANIMATION
  ----------------------------- */
export const accessoryPageAnimation = () => {
	window.scrollTo(0,0)

	const tlIntro = gsap.timeline({ ease: Power2.easeOut })
	tlIntro
		.from('.product__title--line-wrapper .product__title-text', { autoAlpha: 0, yPercent: 100, stagger: 0.2, duration: 0.8 }, '+=0.5')
		.from('.product__title--decorator', { autoAlpha: 0, width: 0,  duration: 0.4 }, '+=0')
		.from('.product__abstract', { autoAlpha: 0, y: 45,  duration: 0.8 }, '-=0.4')
		.add(function () {
			$('body').addClass('scroll')
		})
		.from('.product__image-wrapper', { autoAlpha: 0, yPercent: 10, duration: 0.8 }, '-=0.4')
		
	// Page Color Change
	gsap.fromTo(".accessory-template-default", {
		backgroundColor: gsap.getProperty("html", "--dark")
		}, {
			scrollTrigger: {
			trigger: ".color-light",
			scrub: 1,
			end: "bottom bottom",
			invalidateOnRefresh: true
		},
		backgroundColor: gsap.getProperty("html", "--light")
	})
}

/* CATEGORY ANIMATION
	----------------------------- */
export const categoryPageAnimation = () => {
	
	window.scrollTo(0, 0)

	// Page Color Change
	gsap.fromTo(".tax-product_category", {
		backgroundColor: gsap.getProperty("html", "--light")
	}, {
		scrollTrigger: {
			trigger: ".color-dark",
			scrub: true,
			start: "bottom bottom",
			invalidateOnRefresh: true
		},
		backgroundColor: gsap.getProperty("html", "--dark")
	})

	const tlIntro = gsap.timeline({ ease: Power2.easeOut })
	tlIntro
		.from('.category__title--line-wrapper .category__title-text', { autoAlpha: 0, yPercent: 100, stagger: 0.2, duration: 0.8 }, '+=0.5')
		.from('.category__title--decorator', { autoAlpha: 0, width: 0, duration: 0.4 }, '+=0')
		.from('.category__abstract', { autoAlpha: 0, y: 45, duration: 0.8 }, '-=0.4')
		.from('.category__image-wrapper', { autoAlpha: 0, yPercent: 10, duration: 0.8 }, '-=0.4')


	gsap.from(".category__image-wrapper", {
		scrollTrigger: {
			trigger: ".category__image-wrapper",
			start: "top 64%",
			end: "bottom bottom",
			scrub: 1,
			pinSpacing: true,
			invalidateOnRefresh: true
			// markers: true
		},
		width: '72vw',
		borderRadius: "2.25rem 2.25rem 2.25rem .5rem",
	})
}

/* PAGE DEFAULT ANIMATION
	----------------------------- */
export const pageDefaultAnimation = () => {

	let previousHeight = document.documentElement.scrollHeight;
	window.addEventListener("scroll", () => {
		let currentHeight = document.documentElement.scrollHeight;
		if (currentHeight !== previousHeight) {
			console.log("Page height changed:", currentHeight);
			//ScrollTrigger.refresh()
			previousHeight = currentHeight;
		}
	});

	// Page Hero Animation
	const tlIntro = gsap.timeline({ ease: Power2.easeOut })
	tlIntro.from($('.page--hero .page__title'), { autoAlpha: 0, yPercent: 100, duration: 0.8 }, '+=0.5')
	tlIntro.from($('.page--hero .page__decorator'), { autoAlpha: 0, width: 0, duration: 0.4 }, '+=0')
	tlIntro.from($('.page--hero .page__body'), { autoAlpha: 0, y: 45, duration: 0.8 }, '-=0.4')
	tlIntro.from($('.page--hero .page__image-wrapper, .page--hero .page__video-wrapper'), { autoAlpha: 0, yPercent: 10, duration: 0.8 }, '-=0.4')

	// Page Image Animation
	const sections = gsap.utils.toArray('.page--hero, .page__content--d_basic_section')
	sections.forEach((section) => {
		gsap.from(section.querySelector(".page__image, .page__video"), {
			scrollTrigger: {
				trigger: section.querySelector(".page__image-wrapper, .page__video-wrapper"),
				start: "top 65%",
				end: "bottom bottom",
				scrub: 1,
				pinSpacing: true,
				invalidateOnRefresh: true,
				// markers: true
			},
			width: '72vw',
			borderRadius: "2.25rem 2.25rem 2.25rem .5rem",
		})
	})

	// Body Opacity
	const bodyAlpha = gsap.utils.toArray('.page__content--a_body_repeater .page__body')
	bodyAlpha.forEach(function (item, index) {
		gsap.to(item, {
			scrollTrigger: {
				trigger: item,
				start: "top 50%",
				end: "top 25%",
				toggleActions: "play pause none reverse",
				invalidateOnRefresh: true
			},
			alpha: 1,
			duration: 1.5,
			ease: Power2.easeOut
		})
	})

	// Number Counter
	const items = document.querySelectorAll(".page__content--c_numbers .page__number, .section__numbers-wrapper .section__number");

	ScrollTrigger.create({
		trigger: ".page__content--c_numbers, .section__numbers-wrapper",
		start: "top 65%",
		invalidateOnRefresh: true,
		onEnter: () => {
			gsap.from(items, {
				textContent: 0,
				duration: 2,
				ease: Power2.easeIn,
				snap: { textContent: 1 },
				stagger: 0,
			})
		},
	})


	// Page Color Change
	gsap.fromTo(".product-template-default", {
		backgroundColor: gsap.getProperty("html", "--dark")
	}, {
		scrollTrigger: {
			trigger: ".color-light",
			scrub: 1,
			end: "bottom bottom",
			invalidateOnRefresh: true
		},
		backgroundColor: gsap.getProperty("html", "--light")
	})

	// Sentence List 
	const sectionPinScroll = gsap.utils.toArray('.page__content--n_sentence_list .page__image-wrapper')
	sectionPinScroll.forEach(function (item, index) {
		ScrollTrigger.create({
			trigger: item,
			start: "top 0",
			pin: true,
			pinSpacing: true,
			anticipatePin: 0,
			scrub: 2,
			invalidateOnRefresh: true
			// markers: true
		})

		const sectionPinScrollTl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				start: "top top",
				scrub: 2,
				//markers: true,
				toggleActions: "play pause",
				invalidateOnRefresh: true
			}
		})

		sectionPinScrollTl.to(item, { alpha: 0.3, duration: 2 }, '+=0')
	})

	gsap.to(".page-template-default", {
		scrollTrigger: {
			trigger: ".page-background--gray-light",
			start: "top 88%",
			end: "2rem",
			scrub: 1,
			invalidateOnRefresh: true
		},
		backgroundColor: "#F2F2F2"
	})

	gsap.to(".page-template-default", {
		scrollTrigger: {
			trigger: ".page-background--gray-dark",
			start: "top 88%",
			end: "2rem",
			scrub: 1,
			invalidateOnRefresh: true
		},
		backgroundColor: "#282829"
	})
}

/* POST ANIMATION
	----------------------------- */
export const postPageAnimation = () => {
	// Post Hero Animation
	const tlIntro = gsap.timeline({ ease: Power2.easeOut })
	tlIntro.from($('.post__title'), { autoAlpha: 0, yPercent: 30, duration: .8 }, '+=0.5')
	tlIntro.from($('.post__decorator'), { autoAlpha: 0, width: 0, duration: .4 }, '-=0.4')
	tlIntro.from($('.post__date'), { autoAlpha: 0, y: 45, duration: .8 }, '-=0.4')
	tlIntro.from($('.post__image-wrapper'), { autoAlpha: 0, yPercent: 10, duration: .8 }, '-=0.4')

	// Post Image Animation
	gsap.from(".post__image", {
		scrollTrigger: {
			trigger: ".post__image-wrapper",
			start: "top 65%",
			end: "bottom bottom",
			scrub: 1,
			pinSpacing: true,
			invalidateOnRefresh: true
			// markers: true
		},
		width: '72vw',
		borderRadius: "2.25rem 2.25rem 2.25rem .5rem",
	})
}

/* ANIMATE TEAM GALLERY
	----------------------------- */
const createGallery = () => {
	var _osTop = 0
	var _osLeft = 0
	var _size = $('.section--common-flow .section__gallery-item').length
	var _breckPoints = []
	var _val = 0

	$('.section--common-flow .section__gallery-items.clone').remove()

	for (var i = 1; i <= _size / 3; i++) {
		_breckPoints.push(_val += 3)
		_breckPoints.push(_val += 2)
	}

	$('.section--common-flow .section__gallery-item').each(function (i) {
		const gutter = $(window).height() / 5
		var n = i + 1
		var _randLeft = 32 - Math.floor(Math.random() * 64)
		var _randTop = Math.floor(Math.random() * 32)
		var _posLeft = _osLeft + _randLeft
		var _posTop = _osTop + _randTop
		var _randScale = Math.floor(Math.random() * (100 - 60) + 60) / 100

		_osTop += gutter
		_breckPoints.forEach(function (item, index) {
			if (item == n) {
				_osTop = 0
				_osLeft += gutter * 2
			}
		});

		if (n % 3 == 0) {
			_osTop += gutter / 2
		}

		$(this).css({
			'top': _posTop + 'px',
			'left': _posLeft + 'px',
			'transform': 'scale(' + _randScale + ')',
		})
	})

	var wrapperWidth = $('.section--common-flow .section__gallery-wrapper .section__gallery-item:last-child').offset().left + $('.section--common-flow .section__gallery-wrapper .section__gallery-item:last-child').width() * 1.5
	$('.section--common-flow .section__gallery-wrapper').css('width', wrapperWidth * 2)
	$('.section--common-flow .section__gallery-items').clone().appendTo('.section--common-flow .section__gallery-wrapper').css('left', wrapperWidth).addClass('clone')
	gsap.to('.section--common-flow .section__gallery-wrapper', { duration: 45, x: -wrapperWidth, repeat: -1, ease: Linear.easeNone })
	gsap.to('.section--common-flow .section__gallery-item', { duration: 1, autoAlpha: 1, stagger: .07, delay: 1 })
}

const lazyLoad = () => {
	const lazyLoadInstance = new LazyLoad({
		elements_selector: ".lazy",
		data_src: "src",
	})
}

export const animGallery = () => {
	if ($('.section--common-flow').length) {
		createGallery()
		lazyLoad()
		var w = $(window).width()
	
		$(window).on('resize', function () {
			if ($(window).width() == w) return
			w = $(window).width()
			createGallery()
		})
	}
}